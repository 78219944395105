<template>
  <div class="page page__ueber">
    <HeaderOne
      :imgpath="'assets/img/04_kontakt/header/21_06_07_THI_1106.jpg'"
      :imgposition="'center 60%'"
    />
    <IntroBlockKontakt class="section" />
    <TheFooter class="section" />
  </div>
</template>

<script>
import HeaderOne from "@/components/common/header/HeaderOne";
import IntroBlockKontakt from "@/components/common/introblock/IntroBlockKontakt.vue";
import TheFooter from "../components/common/footer/TheFooter.vue";
export default {
  components: {
    HeaderOne,
    IntroBlockKontakt,
    TheFooter,
  },
};
</script>
<style
  lang="
    scss"
></style>
