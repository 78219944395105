<template>
  <div class="introb1">
    <div class="introb1__wrapper">
      <div class="introb1__content">
        <h1 class="introb1__h1 h1 h1--white">
          Ja, bitte?
        </h1>
        <p class="p p--white introb1__p">
          Sie haben noch Fragen, Anregungen oder Wünsche, die Sie gerne mit uns
          teilen würden? Wir sind ganz Ohr! Am allerliebsten wäre es uns
          allerdings, Sie nutzen unsere Kontaktmöglichkeiten für Lobpreisungen
          jeglicher Art. Wir freuen uns in jedem Fall, von Ihnen zu hören.
        </p>
        <div class="inintrob1__kontaktbox">
          <div class="inintrob1__kontaktleftblock">
            <div class="inintrob1__kontaktitem">
              <a
                :href="kontakt.googlemaps"
                target="_blank"
                class="inintrob1__kontakta"
                >{{ kontakt.address.street }}
              </a>
            </div>
            <div class="inintrob1__kontaktitem">
              <a
                :href="kontakt.googlemaps"
                target="_blank"
                class="inintrob1__kontakta"
                >{{ kontakt.address.plz }} {{ kontakt.address.location }}
              </a>
            </div>
          </div>
          <div class="inintrob1__kontaktrightblock">
            <div class="inintrob1__kontaktitem">
              <a :href="getPhone" class="inintrob1__kontakta">{{
                kontakt.phone
              }}</a>
            </div>
            <div class="inintrob1__kontaktitem">
              <a :href="getMail" class="inintrob1__kontakta">
                {{ kontakt.email }}</a
              >
            </div>
          </div>
        </div>
        <div class="introb1__logo">
          <TheLogo />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import contactInformation from "../../../utilities/siteinformation/contactInformation";

import TheLogo from "@/components/ui/logo/TheLogo.vue";
export default {
  components: { TheLogo },
  setup() {
    const kontakt = contactInformation;
    const getMail = "mailto:" + kontakt.email;
    const getPhone = "tel:" + kontakt.phone;
    return { kontakt, getMail, getPhone };
  },
};
</script>

<style lang="scss"></style>
