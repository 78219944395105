<template>
  <div class="thelogo">
    <svg
      version="1.1"
      class="thelogo__svg"
      id="logo"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 558.8 781.1"
      style="enable-background:new 0 0 558.8 781.1;"
      xml:space="preserve"
    >
      <path
        class="st0"
        d="M290.8,421.7v210.1c0,6.3-5.1,11.4-11.4,11.4c-6.3,0-11.4-5.1-11.4-11.4V421.7c0-6.3,5.1-11.4,11.4-11.4
          C285.7,410.3,290.8,415.4,290.8,421.7L290.8,421.7z M423.2,245.2c-6.3,0-11.4,5.1-11.4,11.4v76.9H290.8v-156h96.6
          c6.3,0,11.4-5.1,11.4-11.4s-5.1-11.4-11.4-11.4H171.3c-6.3,0-11.4,5.1-11.4,11.4s5.1,11.4,11.4,11.4H268v155.9H146.9v-76.9
          c0-6.3-5.1-11.4-11.4-11.4c-6.3,0-11.4,5.1-11.4,11.4V586c0,6.3,5.1,11.4,11.4,11.4c6.3,0,11.4-5.1,11.4-11.4V356.2h264.9V586
          c0,6.3,5.1,11.4,11.4,11.4c6.3,0,11.4-5.1,11.4-11.4V256.6C434.6,250.3,429.5,245.2,423.2,245.2L423.2,245.2z M558.8,390.6
          c0,215.4-125.3,390.5-279.4,390.5S0,605.9,0,390.5S125.3,0,279.4,0S558.8,175.2,558.8,390.6L558.8,390.6z M536.1,390.6
          c0-202.8-115.2-367.9-256.8-367.9S22.6,187.7,22.6,390.6s115.2,367.9,256.7,367.9S536.1,593.4,536.1,390.6L536.1,390.6L536.1,390.6z
          "
      />
    </svg>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.thelogo__svg {
  width: 100%;
  height: 100%;
}
.st0 {
  fill: $main-color-2;
}
</style>
